<dpod-dialog-layout [title]="title">
  <ng-container ngProjectAs="page-content">
    <div class="mt-4">
      <table class="table">
        <tbody>
          <tr>
            <th>Pod title</th>
            <td>{{ currentPod.name }}</td>
          </tr>
          <tr>
            <th>Memory</th>
            <td>{{ currentPod.memory }}</td>
          </tr>
          <tr>
            <th>Cpu</th>
            <td>{{ currentPod.cpu }}</td>
          </tr>
          <tr>
            <th>Storage</th>
            <td>{{ currentPod.storage }}</td>
          </tr>
          <tr>
            <th>State</th>
            <td>{{ currentPod.state }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div align="center" class="mt-4">
        <ng-container *ngIf="!loading">
            <div style="font-family: 'latobold';">{{ confirmText }}</div>
            <div class="mt-4">
              <button class="confirm-yes" (click)="onConfirm()">
                <mat-icon>done</mat-icon>&nbsp;
                {{ confirmButtonText }}
              </button>&nbsp;&nbsp;
              <button class="confirm-no" (click)="onCancel()">
                <mat-icon>close</mat-icon>&nbsp;
                {{ cancelButtonText }}
              </button>&nbsp;&nbsp;
            </div>
        </ng-container>
        <ng-container *ngIf="loading">
            <dpod-loading [message]="titlePrefix + '... Please wait'"></dpod-loading>
        </ng-container>
    </div>
  </ng-container>
</dpod-dialog-layout>
