import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  NgZone,
} from '@angular/core';

import { DashboardService } from './dashboard.service';
import { PodService } from '../pods/pod.service';
import { SettingsService } from '../../core/bootstrap/settings.service';
import { ActivatedRoute } from '@angular/router';

declare var ApexCharts: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styles: [
    `
      .mat-raised-button {
        margin-right: 8px;
        margin-top: 8px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DashboardService],
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {

  stats = this.dashboardSrv.getStats();
  podLoading$ = this.podService.loading$;
  podError$ = this.podService.errors$;
  pods$ = this.podService.getAll();
  user: any;

  constructor(
    private dashboardSrv: DashboardService,
    private podService: PodService,
    private userInfo: SettingsService
  ) {}

  ngOnInit() {
    this.user = this.userInfo.getUser();
  }

  ngAfterViewInit() {}
  ngOnDestroy() {}
}
