import { Component, OnInit } from '@angular/core';
import { PodBackup } from './pod-backup.model';
import { formatDate } from '@angular/common';

@Component({
  selector: 'dpod-pod-backup',
  templateUrl: './pod-backup.component.html',
  styleUrls: ['./pod-backup.component.css'],
})
export class PodBackupComponent implements OnInit {
  today = new Date().toISOString();

  podid = 1000;
  type = 'Manually';

  podbackups: PodBackup[] = [];

  constructor() {}

  ngOnInit(): void {}

  doBackup() {
    this.podid = this.podid + 11;
    this.formatDate();
    this.type = 'Manually';

    return this.podbackups.push(
      new PodBackup(this.podid, this.formatDate(), this.type)
    );
  }

  formatDate() {
    const newDate = new Date(
      new Date().setHours(new Date().getHours() + 3)
    ).toISOString();
    const day = newDate.substr(0, 10);
    const time = newDate.substr(11, 8);
    return `Backup - ${day}, ${time}`;
  }
}
