import { Pod } from './pod';

export class User {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  surname:string;
  token: string;
  avatar: string;
  username: string;
  pods: Pod[];
}
