import { Component, OnInit } from '@angular/core';
import { PodService } from '../pod.service';
import { ActivatedRoute } from '@angular/router';
import { switchMap, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
// import { HttpClient } from '@angular/common/http';
import { HttpRequestsService } from '../../../shared/services/http.service';
import { Pod, PodState } from '@dpod/api-interfaces';

// tslint:disable-next-line: nx-enforce-module-boundaries

interface Food {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'dpod-pod-details',
  templateUrl: './pod-details.component.html',
  styleUrls: ['./pod-details.component.css'],
})

export class PodDetailsComponent implements OnInit {
  pods$: Observable<Pod>;
  podtitle: any;
  _podState: PodState;
  statemenu: any;
  podId: string;

  constructor(
    private http: HttpRequestsService,
    private activatedRoute: ActivatedRoute,
    private podService: PodService
  ) {}

  ngOnInit(): void {
    // this._podState = 'Running';
    this.pods$ = this.activatedRoute.params.pipe(
      switchMap((params) => {
        this.podId = params.id;
        return this.podService.getByKey(params.id);
      }),
      tap((data: Pod) => {
        if(data) {
          this._podState = data.state;
        }
        // console.log({data});
      })
    );
  }

  get showBoot(): boolean {
    return (['Idle', 'Offline'] as PodState[]).includes(this._podState);
  }

  get showReboot(): boolean {
    return (['Running'] as PodState[]).includes(this._podState);
  }

  get showShutdown(): boolean {
    return (['Running'] as PodState[]).includes(this._podState);
  }

  get showDelete(): boolean {
    return (['Offline'] as PodState[]).includes(this._podState);
  }

  podShutdown(): void {
    // this._podState = 'Offline';
    console.log('Shutdown State: ', this._podState);
    this.http.get(`pod/${this.podId}/shutdown`);
  }

  podReboot() {
    // // this._podState = 'Running';
    // console.log('Reboot State: ', this._podState);
    // this.http.get(`pod/${this.podId}/reboot`);
    // setTimeout(() => {
    //   this._podState = 'Running';
    //   console.log('Reboot State: ', this._podState);
    // }, 3000);
  }

  podBoot() {
    // tslint:disable-next-line: triple-equals
    // if (this._podState == 'Offline') {
    //   // this._podState = 'Booting';
    //   setTimeout(() => {
    //     this._podState = 'Running';
    //     console.log('Boot State: ', this._podState);
    //   }, 3000);
    // } else this._podState = 'Running';
    // this.http.get(`pod/${this.podId}/boot`);
    // console.log('Boot State: ', this._podState);
  }

  podDelete() {
    // console.log({podId});
  }
}
