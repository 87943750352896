<div
  class="col-sm-12 mb-4"
  align="center"
  style="font-family: latobold; font-size: 30px;"
>
  Welcome to dpod
</div>
<div class="login-page" style="color: black;">
  <form [formGroup]="loginForm">
    <div class="row">
      <div class="col-sm-12">
        <mat-form-field appearance="outline">
          <mat-label>User name</mat-label>
          <mat-icon matPrefix style="display: inline-flex; align-items: center;"
            >person</mat-icon
          >
          <input
            type="text"
            matInput
            autocomplete="off"
            formControlName="username"
          />
        </mat-form-field>
      </div>
      <div class="col-sm-12">
        <mat-form-field appearance="outline">
          <mat-label>Password</mat-label>
          <mat-icon matPrefix style="display: inline-flex; align-items: center;"
            >lock</mat-icon
          >
          <mat-icon
            matSuffix
            style="display: inline-flex; align-items: center;"
            style="cursor: pointer;"
            (click)="showPassword = !showPassword"
          >
            {{ showPassword ? 'visibility_on' : 'visibility_off' }}
          </mat-icon>
          <input
            [type]="showPassword ? 'text' : 'password'"
            matInput
            autocomplete="off"
            formControlName="password"
          />
        </mat-form-field>
      </div>
      <ng-container *ngIf="!loggingIn">
        <div class="col-sm-12">
          <button
            type="button"
            class="login-btn"
            [disabled]="!loginForm.valid"
            (click)="login()"
          >
            Login
          </button>
        </div>
        <div class="col-sm-12 mt-2" align="center" style="color: #2596be;">
          <b>OR</b>
        </div>
        <div class="col-sm-12" align="center" style="color: #2596be;">
          <button mat-button type="button" [routerLink]="'/auth/register'">
            <mat-icon>person_add</mat-icon> Create account
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="loggingIn">
        <div class="col-sm-12">
          <dpod-loading></dpod-loading>
        </div>
      </ng-container>
    </div>
  </form>
</div>
