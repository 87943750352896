<mat-toolbar class="header-content">
  <div [ngClass]="routeElements" style="display: inline-flex; align-items: center; width: 100%;">
    <img
      *ngIf="icon"
      src="../../../../assets/images/{{ icon }}"
      height="30px"
      width="30px"
    />&nbsp;&nbsp;
    <span>{{ title }}</span>
    <div class="ms-auto" *ngIf="showClose">
      <button mat-flat-button style="display: inline-flex; align-items: center; background-color: transparent;" (click)="onClose()">
        <mat-icon>close</mat-icon>&nbsp; Close
      </button>
    </div>
  </div>
</mat-toolbar>
<mat-divider></mat-divider>
