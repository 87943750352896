import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ROUTE_ANIMATIONS_ELEMENTS } from '../../animations/router-animation';

@Component({
  selector: 'dpod-shared-layout',
  templateUrl: './shared-layout.component.html',
  styleUrls: ['./shared-layout.component.css']
})
export class SharedLayoutComponent implements OnInit {
  @Input() title = '';
  @Input() icon = '';
  @Input() showAdd = false;
  @Output() add = new EventEmitter()
  routeElements = ROUTE_ANIMATIONS_ELEMENTS;
  constructor() { }

  ngOnInit(): void {
  }

  onAdd() {
    this.add.emit()
  }

}
