<div class="header">
  <dpod-page-header
    [title]="title"
    [icon]="icon"
    [showClose]="true"
    (close)="onClose()"
  ></dpod-page-header>
</div>
<div class="dialog-content">
  <ng-content select="page-content"></ng-content>
</div>
