<breadcrumb></breadcrumb>

<div class="matero-row" fxLayout="row wrap">
  <!-- Statistics -->
  <!-- <div class="matero-col" fxFlex.gt-sm="25" fxFlex.gt-xs="50" fxFlex="100"
       *ngFor="let stat of stats">
    <mat-card [ngClass]="['text-white', stat.color]">
      <mat-card-title class="f-s-12 f-w-100">{{stat.title}}</mat-card-title>
      <mat-card-content>
        <h2>{{stat.amount}}</h2>
        <mtx-progress [value]="stat.progress.value"
                      height="2px"
                      foreground="rgba(255,255,255,1)"
                      background='rgba(0,0,0,.5)'>
        </mtx-progress>
        <small class="text-muted">Monthly</small>
      </mat-card-content>
    </mat-card>
  </div> -->

  <div class="matero-col" fxFlex.gt-sm="100" fxFlex="100">
    <mat-card>
      <mat-card-subtitle>My Pods</mat-card-subtitle>
      <mat-progress-bar *ngIf="podLoading$ | async"></mat-progress-bar>
      <mat-error *ngIf="podError$ | async">
        {{ (podError$ | async).payload.data.error.message }}
      </mat-error>
      <ng-container *ngIf="!(podLoading$ | async)">
        <mat-card-content
          *ngIf="(pods$ | async) && (pods$ | async)?.length == 0"
        >
          You have no pods. You can add a pod by clicking the floating button.
        </mat-card-content>
        <mat-list *ngIf="(pods$ | async)?.length > 0">
          <ng-container *ngFor="let pod of pods$ | async">
            <div *ngIf="pod.poduser == user.username">
              <mat-divider></mat-divider>
              <mat-list-item>
                <img
                  matListAvatar
                  src="https://pbs.twimg.com/profile_images/1042795600507486209/J4ut4r2r_400x400.jpg"
                  alt=""
                />
                <h3 matLine>{{ pod.name }}</h3>
                <p matLine>
                  <span style="font-size: 12px;"
                    >DHIS2 Version: {{ pod?.version }} RAM
                    {{ pod?.resource.memory }} CPU
                    {{ pod.resource.cpu }} Storage {{ pod?.resource.storage }}
                  </span>
                </p>
                <button
                  mat-raised-button
                  color="primary"
                  routerLink="/pods/{{ pod.id }}"
                >
                  View Details
                </button>
              </mat-list-item>
            </div>
          </ng-container>
        </mat-list>
      </ng-container>
    </mat-card>
  </div>
</div>
