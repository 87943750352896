import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'dpod-dialog-layout',
  templateUrl: './dialog-layout.component.html',
  styleUrls: ['./dialog-layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogLayoutComponent implements OnInit {
  @Input() title = '';
  @Input() icon;
  constructor(private dialog:MatDialog) { }

  ngOnInit(): void {
  }

  onClose() {
    this.dialog.closeAll();
  }

}
