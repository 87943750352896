import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
//import { environment } from '@dpod/env';
import { environment } from '../../../environments/environment';

import { ToastrService } from 'ngx-toastr';
import { TokenService } from '../authentication/token.service';
import { SettingsService } from '@dpod/client/core/bootstrap/settings.service';

@Injectable()
export class DefaultInterceptor implements HttpInterceptor {
  constructor(
    private _router: Router,
    private _toastr: ToastrService,
    private _token: TokenService,
    private _settings: SettingsService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Add server host
    const url  = req.url;
    const headers = {
      'Accept': 'application/json',
      'Accept-Language': this._settings.language,
      'Authorization': `Basic ${this._token.get().token}`,
    };
    if (!url.includes('/api/')) {
      console.log('setting header');
      const _req = req.clone({url, setHeaders: headers});
      return next.handle(_req);
    }

    const newReq = req.clone({ url, setHeaders: headers, withCredentials: true });

    return next.handle(newReq).pipe(
      mergeMap((event: HttpEvent<any>) => this._handleOkReq(event)),
      catchError((error: HttpErrorResponse) => this._handleErrorReq(error))
    );
  }

  private _goto(url: string) {
    setTimeout(() => this._router.navigateByUrl(url));
  }

  private _handleOkReq(event: HttpEvent<any>): Observable<any> {
    // TODO study the logic inside if block
    /* if (event instanceof HttpResponse) {
      const body: any = event.body;
      // failure: { code: **, msg: 'failure' }
      // success: { code: 0,  msg: 'success', data: {} }
      if (body && body.code !== 0) {
        console.log('body', body);
        if (body.msg && body.msg !== '') {
          this._toastr.error(body.msg);
        }
        return throwError([]);
      } else {
        return of(event);
      }
    } */
    // Pass down event if everything is OK
    return of(event);
  }

  private _handleErrorReq(error: HttpErrorResponse): Observable<never> {
    switch (error.status) {
      case 401:
        this._goto(`/auth/login`);
        break;
      case 403:
      case 404:
      case 500:
        this._goto(`/sessions/${error.status}`);
        break;
      default:
        if (error instanceof HttpErrorResponse) {
          console.error('ERROR', error);
          this._toastr.error(error.error.msg || `${error.status} ${error.statusText}`);
        }
        break;
    }
    return throwError(error);
  }
}
