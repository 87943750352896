import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { MenuService, SettingsService, TokenService } from '@dpod/client/core';
import { User } from '@dpod/api-interfaces';
import { Router } from '@angular/router';
import { routeAnimations, ROUTE_ANIMATIONS_ELEMENTS } from '@dpod/client/shared/animations/router-animation';

@Component({
  selector: 'dpod-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss'],
  animations:[routeAnimations]
})
export class MainNavComponent {
  menus = [
    // {
    //   title:'Dashboard',
    //   route:'route',
    //   icon:'dashboard.png'
    // },
    {
      title:'Pods',
      route:'pods',
      icon:'dhis-white.png'
    },
    {
      title:'Account',
      route:'account',
      icon:'user-white.png'
    }
  ]
  smallSideNav = false;
  smallScreen = false;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  user:User;
  routeElements = ROUTE_ANIMATIONS_ELEMENTS;
  constructor(
    private breakpointObserver: BreakpointObserver, 
    private settingService:SettingsService,
    private _router: Router,
    private _token: TokenService,
    private _menu: MenuService,
    ) {
    this.breakpointObserver.observe(Breakpoints.Handset).subscribe(result => {
      this.smallScreen = result.matches;
    })
    // .pipe(
    //   map(result => result.matches),
    //   shareReplay()
    // );
  }

  ngOnInit() {
    this.user = this.settingService.getUser();
    console.log('user', this.user);
  }

  onLogout() {
    this._token.clear();
    this.settingService.removeUser();
    this._menu.reset();
    this._router.navigateByUrl('/auth/login');
  }

}
