import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ROUTE_ANIMATIONS_ELEMENTS } from '../../animations/router-animation';
import { TableConfiguration } from './tableconfiguration';
@Component({
  selector: 'dpod-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class DataTableComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  dataSource = new MatTableDataSource([]);
  @Input() data = [];
  @Input() loading = {};
  @Input() loadingMessage = '';
  @Input() confirmText = 'Are you sure';
  @Input() confirming = {};
  @Input() tableConfiguration: TableConfiguration = {
    tableColumns: [],
    extraButtons: [],
    emptyMessage:'No Data',
    actionIcons: {
      edit: false,
      isDelete: false,
      more: false
    }
  };
  @Output() extraButton = new EventEmitter<{ key: string; id: string; }>();
  displayedColumns: string[];
  routeElements = ROUTE_ANIMATIONS_ELEMENTS;
  constructor() {
    // this.dataSource = new MatTableDataSource([]);
  }

  ngOnInit(): void {
    this.initData();
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    // this.displayedColumns = ['position', ...this.tableConfiguration.tableColumns.map(column => column.name)];
  }

  ngOnChanges() {
    this.initData();
  }

  // ngAfterViewInit(): void {
  //   this.initData();
  // }

  initData() {
    this.displayedColumns = ['position', ...this.tableConfiguration.tableColumns.map(column => column.name)];
    const { edit, isDelete, more } = this.tableConfiguration.actionIcons ?? { edit: false, isDelete: false, more: false };
    if (edit || isDelete || more || this.tableConfiguration.extraButtons.length) {
      this.displayedColumns.push('actions');
    }
    console.log('displayed columns', this.displayedColumns);
    this.dataSource = new MatTableDataSource(this.data);
  }

  extraButtonClicked(key: string, id: string) {
    this.confirming = {};
    this.confirmText = 'Are you sure';
    this.extraButton.emit({ key, id });
  }
}
