<div class="row">
  <div class="col-md-6">
    <button type="button" class="btn btn-outline-primary btn-block btn-sm" (click)="doBackup()">Backup now manually</button>
  </div>
  <div class="col-md-6">
    <div class="dropdown">
        <button type="button" disabled class="btn btn-outline-primary btn-block btn-sm dropdown-toggle" data-toggle="dropdown">
          Schedule Backup
        </button>
        <div class="dropdown-menu">
          <a class="dropdown-item" href="#">Link 1</a>
          <a class="dropdown-item" href="#">Link 2</a>
          <a class="dropdown-item" href="#">Link 3</a>
        </div>
      </div>
</div>
</div>
<div class="row">
  <div class="col-md-12">
      <hr>
    <ul class="list-group list-group-flush">
        <li class="list-group-item" *ngFor="let podbackup of podbackups">
            <div class="row">
                <div class="col-sm-1">
                    {{ podbackup.id }}
                  </div>
                  <div class="col-sm-5">
                    {{ podbackup.backupdatetime }}
                  </div>
                  <div class="col-sm-5">
                    <span style="font-style: italic">{{ podbackup.backuptype }}</span>
                  </div>
                  <div class="col-sm-1">
                    <a href="#" class=""><span class="badge badge-warning">Restore</span></a>
                  </div>
              </div>
        </li>
      </ul>
  </div>
</div>
