import { NgModule } from '@angular/core';
import { SharedModule } from '@dpod/client/shared/shared.module';

import { AdminLayoutComponent } from './admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { MainNavComponent } from './admin-layout/main-nav/main-nav.component';

@NgModule({
  declarations: [
    AdminLayoutComponent,
    AuthLayoutComponent,
    MainNavComponent,
  ],
  imports: [SharedModule],
})
export class ThemeModule {}
