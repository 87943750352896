import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Pod, PodAction, PodStatus } from '@dpod/api-interfaces';
import { PodService } from '@dpod/client/routes/pods/pod.service';
import { PodModel } from '@dpod/client/store/models/pod.model';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'dpod-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {
  confirmButtonText = 'Confirm';
  cancelButtonText = 'Cancel';
  // confirmText = 'Are you sure'
  title = '';
  currentPod: PodModel;
  loading = false;
  titlePrefix = '';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { confirmText: string; title: string; confirmButtonText: string; cancelButtonText: string; key: string; pod: PodModel; },
    private dialog: MatDialogRef<ConfirmDialogComponent>,
    private podService: PodService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    if (this.data) {
      this.currentPod = this.data.pod;
      if (this.data.key === PodAction.BOOT) {
        this.titlePrefix = 'Booting';
      } else if (this.data.key === PodAction.REBOOT) {
        this.titlePrefix = 'Rebooting';
      } else if (this.data.key === PodAction.DELETE) {
        this.titlePrefix = 'Deleting';
      } else if (this.data.key === PodAction.SHUTDOWN) {
        this.titlePrefix = 'Shutting down';
      }
      this.title = `${this.titlePrefix} ${this.currentPod.name}`;
      this.confirmButtonText = this.data.confirmButtonText ?? 'Confirm';
      this.cancelButtonText = this.data.cancelButtonText ?? 'Cancel';
      // this.confirmText = this.data.confirmText ?? 'Are you sure';
    }
  }

  get confirmText() {
    let text = 'Are you sure';
    if (this.data.key === PodAction.BOOT) {
      text = 'Are you sure you want to boot this pod?';
    } else if (this.data.key === PodAction.REBOOT) {
      text = 'Are you sure you want to reboot this pod?';
    } else if (this.data.key === PodAction.DELETE) {
      text = 'Are you sure you want to delete this pod?';
    } else if (this.data.key === PodAction.SHUTDOWN) {
      text = 'Are you sure you want to shut down this pod?';
    }
    return text;
  }


  onCancel(update = false) {
    this.dialog.close(update);
  }

  async onConfirm() {
    let successMessage = 'Completed successfully';
    this.loading = true;
    try {
      if (this.data.key === PodAction.BOOT) {
        await this.podService.podBoot(this.currentPod.id);
        successMessage = 'Pod booted successfully'
      }else if (this.data.key === PodAction.REBOOT) {
        await this.podService.podReboot(this.currentPod.id);
        successMessage = 'Pod rebooted successfully'
      } else if (this.data.key === PodAction.SHUTDOWN) {
        await this.podService.podShutdown(this.currentPod.id);
        successMessage = 'Pod shutdown successfully'
      } else if (this.data.key === PodAction.DELETE) {
        await this.podService.podDelete(this.currentPod.id);
        successMessage = 'Pod deleted successfully'
      }
      this.toastr.success(successMessage);
      this.onCancel(true);
    } catch (e) {
      console.error('Failed',e);
      this.toastr.error(`Failed to ${this.data.key} pod`)
    }
    this.loading = false;

  }

  get status() {
    if (this.data.key === PodAction.BOOT) {
      return PodStatus.BOOTING;
    } else if (this.data.key === PodAction.REBOOT) {
      return PodStatus.REBOOTING;
    } else if (this.data.key === PodAction.DELETE) {
      return PodStatus.DELETING;
    } else if (this.data.key === PodAction.SHUTDOWN) {
      return PodStatus.SHUTTINGDOWN;
    } else {
      return this.currentPod?.state;
    }
  }
}
