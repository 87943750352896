import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'dpod-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class LoadingComponent implements OnInit {
  @Input() message = 'Loading...'
  constructor() { }

  ngOnInit(): void {
  }

}
