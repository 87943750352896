<mat-card>
  <mat-card-header>
    <div class="container">
      <div class="row">
        <div class="col-8">
          <mat-card-title style="margin-top: 1%;">
            <img
              matListAvatar
              src="https://pbs.twimg.com/profile_images/1042795600507486209/J4ut4r2r_400x400.jpg"
              alt=""
              style="width: 5%; height: 5%;"
            />
            {{ (pods$ | async)?.name }}
          </mat-card-title>
        </div>
        <div class="col-4">
          <mat-menu #menu1="matMenu">
            <button *ngIf="showBoot" mat-menu-item (click)="podBoot()"><mat-icon>trending_up</mat-icon> Boot</button>
            <button *ngIf="showReboot" mat-menu-item (click)="podReboot()"><mat-icon>refresh</mat-icon> Reboot</button>
            <button *ngIf="showShutdown" mat-menu-item (click)="podShutdown()"><mat-icon>power_settings_new</mat-icon> Shutdown</button>
            <button color="warn" *ngIf="showDelete" mat-menu-item (click)="podDelete()"><mat-icon>delete</mat-icon> Delete</button>
          </mat-menu>
          <button
            style="float: right; margin-bottom: 10%;"
            mat-button
            [matMenuTriggerFor]="menu1"
            aria-label="Open Menu"
            *ngIf="pods$ | async"
          >
            {{ _podState }}
            <mat-icon class="material-icons">arrow_drop_down</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <!-- <div fxFlex='80'></div> -->
  </mat-card-header>
  <mat-card-content>
    <mat-tab-group class="m--16">
      <mat-tab label="Summary">
        <div class="m-16">
          <dpod-pod-summary></dpod-pod-summary>
        </div>
      </mat-tab>
      <mat-tab label="Networking">
        <div class="m-16">
          <dpod-pod-networking></dpod-pod-networking>
        </div>
      </mat-tab>
      <mat-tab label="Resize">
        <div class="m-16" >
          <dpod-pod-resize [podId]="podId"></dpod-pod-resize>
        </div>
      </mat-tab>
      <mat-tab label="Backup">
        <div class="m-16">
          <dpod-pod-backup></dpod-pod-backup>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>
</mat-card>
