import { Component, Inject, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PodModel } from '@dpod/client/store/models/pod.model';
import { fadeIn } from '../../animations/router-animation';
import { CommonService } from '../../services/common.service';
import { HttpRequestsService } from '../../services/http.service';

@Component({
  selector: 'dpod-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations:[fadeIn]
})
export class PaymentComponent implements OnInit {
  currentPod: PodModel;
  numberOfDays = '1';
  paymentPhoneNumber: string;
  processing = false;
  inputForm:FormGroup
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { pod: PodModel; },
    private http: HttpRequestsService,
    private dialog: MatDialogRef<PaymentComponent>,
    private formBuilder: FormBuilder,
    private commonService:CommonService,
    private changeDetectorRef:ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.inputForm = this.formBuilder.group({
      phoneNumber:['',[ Validators.required, Validators.pattern('[0-9]{9}$')]]
    })
    if (this.data.pod) {
      this.currentPod = this.data.pod;
      console.log('pod', this.data.pod);
    }
  }

  get days() {
    return parseFloat(this.numberOfDays);
  }

  get paymentAmount() {
    return 5000 * parseFloat(this.numberOfDays);
  }

  async processPayment() {
    this.processing = true;
    const phoneNumber = this.inputForm.value['phoneNumber']
    try {
      console.log('processing payment');
      const paymentPayload = {
        channel: 'MPESA',
        amount: this.paymentAmount,
        phoneNumber:`255${phoneNumber}`,
        numberOfDays:parseFloat(this.numberOfDays),
        item: 'Pod resource',
        pod: this.currentPod.id
      };
      await this.http.post('auth/payments', paymentPayload).toPromise();
      this.commonService.success('Payment processed successfully');
      this.dialog.close();
    } catch (e) {
      console.error('Failed to process payment', e);
      this.commonService.error('Failed to process payment');
    }
    this.processing = false;
  }

  get hasError() {
    const error = !this.inputForm.valid && this.inputForm.touched
    // this.changeDetectorRef.detectChanges()
    return error;
  }

  get invalidNumber() {
    console.log(this.inputForm)
    // this.changeDetectorRef.detectChanges()
    return this.inputForm.touched && this.inputForm.get('phoneNumber').hasError('pattern');
  }

}
