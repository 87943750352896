<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #drawer
    class="sidenav"
    fixedInViewport
    [attr.role]="smallScreen ? 'dialog' : 'navigation'"
    [mode]="smallScreen ? 'over' : 'side'"
    [opened]="!smallScreen"
  >
    <mat-toolbar style="color: white; font-family: 'latobold';">
      Dpod
    </mat-toolbar>
    <mat-nav-list>
      <ng-container *ngFor="let menu of menus" href="#">
        <a
          [routerLink]="menu.route"
          style="color: white; font-family: 'latobold';"
          mat-list-item
          routerLinkActive="active-route"
        >
          <img
            src="assets/images/{{ menu.icon }}"
            alt=""
            height="20px"
            width="20px"
          />
          &nbsp;&nbsp;
          {{ menu.title }}
        </a>
      </ng-container>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar style="background-color: white;">
      <div class="d-flex" style="width: 100%; align-items: center">
        <button
          type="button"
          aria-label="Toggle sidenav"
          mat-icon-button
          (click)="drawer.toggle()"
        >
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
        <span>dpod</span>
        <div
          class="ms-auto"
          style="cursor: pointer; display: inline-flex; align-items: center;"
          [matMenuTriggerFor]="menu"
        >
          <!-- <mat-icon style="cursor: pointer" [matMenuTriggerFor]="menu"
          >person</mat-icon
        > -->
          <img src="assets/images/user.png" alt="" height="30px"  />&nbsp;&nbsp;
          <div
            style="
              height: 40px;
              width: 1px;
              background-color: rgb(160, 160, 160);
            "
          ></div>
          &nbsp;&nbsp;
          <span>{{ user?.firstName }}&nbsp;{{user?.surname}}</span>
        </div>
        <div>
          <mat-menu #menu>
            <button mat-menu-item (click)="onLogout()" class="user-menus">
              <mat-icon>exit_to_app</mat-icon>Logout
            </button>
          </mat-menu>
        </div>
      </div>
    </mat-toolbar>
    <div [@routeAnimations]="o.isActivated ? o.activatedRoute : ''">
      <router-outlet #o="outlet"></router-outlet>
    </div>
    <!-- Add Content Here -->
  </mat-sidenav-content>
</mat-sidenav-container>
