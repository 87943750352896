<!-- <div class="w-full h-full" fxLayout="row wrap" fxLayoutAlign="center center">
  <mat-card class="mat-elevation-z4 m-b-0" style="max-width: 380px;">
    <mat-card-title class="m-b-24">
      {{'register.welcome' | translate}}, <br />
      {{'register.title' | translate}}
    </mat-card-title>

    <mtx-progress *ngIf='loggingIn'
                      height="2px"
                      foreground="rgba(255,255,255,1)"
                      background='rgba(0,0,0,.5)'>
        </mtx-progress>
    <form *ngIf='!loggingIn' class="form-field-full" [formGroup]="registerForm">
      <mat-form-field appearance="outline">
        <mat-label>{{'login.username' | translate}}</mat-label>
        <input matInput formControlName="username" required>
        <mat-error *ngIf="registerForm.get('username').invalid">
          {{'validations.required' | translate}}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{'login.password' | translate}}</mat-label>
        <input matInput type="password" formControlName="password"
               required>
        <mat-error *ngIf="registerForm.get('password').invalid">
          {{'validations.required' | translate}}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{'register.confirm-password' | translate}}</mat-label>
        <input matInput type="password" formControlName="confirmPassword" required>
        <mat-error *ngIf="registerForm.get('confirmPassword').hasError('required')">
          {{'validations.required' | translate}}
        </mat-error>
        <mat-error *ngIf="registerForm.get('confirmPassword').hasError('confirm')"
                   translate [translateParams]="{value: 'login.password' | translate}">
          {{'validations.inconsistent'}}
        </mat-error>
      </mat-form-field>

      <div class="m-y-16" fxLayout="row" fxLayoutAlign="space-between center">
        <mat-checkbox>{{'register.agree' | translate}}</mat-checkbox>
      </div>
      <button class="w-full" mat-raised-button color="primary" (click)="register()">
        {{'register.register' | translate}}
      </button>

      <div class="m-t-16">{{'register.have-an-account' | translate}}?
        <a routerLink="/auth/login">{{'login.login' | translate}}</a></div>
    </form>

  </mat-card>
</div> -->
<div class="col-sm-12 mb-4" align="center" style="font-family: latobold;">
  <span style="font-size: 30px;">{{ 'register.welcome' | translate }},</span>
  <br />
  {{ 'register.title' | translate }}
</div>
<div class="register-page" style="color: black;">
  <form [formGroup]="registerForm">
    <div class="row">
      <div class="col-sm-12">
        <mat-form-field appearance="outline">
          <mat-label>First name</mat-label>
          <input type="text" matInput formControlName="firstname" />
        </mat-form-field>
      </div>
      <div class="col-sm-12">
        <mat-form-field appearance="outline">
          <mat-label>Surname</mat-label>
          <input type="text" matInput formControlName="surname" />
        </mat-form-field>
      </div>
      <div class="col-sm-12">
        <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input type="text" matInput formControlName="email" />
        </mat-form-field>
      </div>
      <div class="col-sm-12">
        <mat-form-field appearance="outline">
          <mat-label>User name</mat-label>
          <input type="text" matInput formControlName="username" />
        </mat-form-field>
      </div>
      <div class="col-sm-12">
        <mat-form-field appearance="outline">
          <mat-label>Password</mat-label>
          <input
            [type]="showPassword ? 'text' : 'password'"
            matInput
            formControlName="password"
          />
          <mat-icon
            matSuffix
            style="cursor: pointer;"
            (click)="showPassword = !showPassword"
          >
            {{ showPassword ? 'visibility_on' : 'visibility_off' }}
          </mat-icon>
        </mat-form-field>
      </div>
      <div class="col-sm-12">
        <mat-form-field appearance="outline">
          <mat-label>Confirm Password</mat-label>
          <input
            [type]="showConfirmPassword ? 'text' : 'password'"
            matInput
            formControlName="confirmPassword"
          />
          <mat-icon
            matSuffix
            style="cursor: pointer;"
            (click)="showConfirmPassword = !showConfirmPassword"
          >
            {{ showConfirmPassword ? 'visibility_on' : 'visibility_off' }}
          </mat-icon>
        </mat-form-field>
      </div>
      <ng-container *ngIf="!loggingIn">
        <div class="col-sm-12">
          <button class="login-btn" (click)="register()">Register</button>
        </div>
        <div class="col-sm-12 mt-4" style="font-family: latobold; color:#2596be;">
          Already have an acoount? &nbsp;
          <button mat-button type="button" [routerLink]="'/auth/login'">
            <mat-icon>exit_to_app</mat-icon> Login
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="loggingIn">
        <div class="col-sm-12">
          <dpod-loading></dpod-loading>
        </div>
      </ng-container>
    </div>
  </form>
</div>
