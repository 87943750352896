<mat-card style="width: 45%; float:left; margin: 1%;">
  <mat-card-subtitle>CPU Usage chart</mat-card-subtitle>
  <mat-card-content>
    <img
      mat-car-img
      src="https://www.oakvillegym.com/wp-content/uploads/2019/09/coming-soon.png"
      style="
        width: 70%;
        display: block;
        margin-left: auto;
        margin-right: auto;
      "
    />
  </mat-card-content>
</mat-card>
