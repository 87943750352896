import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import {
  TokenService,
  StartupService,
  SettingsService,
} from '@dpod/client/core';
import { HttpClient } from '@angular/common/http';
import { User } from '@dpod/api-interfaces';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls:['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public _username = '';
  loginForm: FormGroup;
  loggingIn = false;
  loggingError;
  showPassword = false;
  constructor(
    private _fb: FormBuilder,
    private toastr: ToastrService,
    private http: HttpClient,
    private _router: Router,
    private _token: TokenService,
    private _startup: StartupService,
    private _settings: SettingsService
  ) {
    /*this.loginForm = this._fb.group({
      username: ['', [Validators.required, Validators.pattern('ng-matero')]],
      password: ['', [Validators.required, Validators.pattern('ng-matero')]],
    });*/
    this.loginForm = this._fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
  }

  ngOnInit() {}

  get username() {
    return this.loginForm.get('username');
  }

  get password() {
    return this.loginForm.get('password');
  }

  login() {
    this.loggingIn = true;
    this.http
      .post('api/auth/login', {
        username: this.username.value,
        password: this.password.value,
      })
      .subscribe(
        (user: User) => {
          this.loggingIn = false;
          // Set user info
          user.token = btoa(`${this.username.value}:${this.password.value}`);
          const { token } = user;


          this._settings.setUser(user);
          // Set token info
          this._token.set({ token });
          // Regain the initial data
          this._startup.load().then(() => {
            let url = this._token.referrer!.url || '/';
            if (url.includes('/auth')) {
              url = '/';
            }
            this._router.navigateByUrl(url);
          });
        },
        (error) => {
          console.log(error);
          this.loggingIn = false;
          this.toastr.error(error.error.message);
        }
      );
  }
}
