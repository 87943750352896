<dpod-dialog-layout [title]="'Payment'" [icon]="'money.png'">
  <ng-container ngProjectAs="page-content">
    <div class="mt-2 d-flex payment-card" @fadeIn>
      <div>
        <table class="table data-entry-table">
          <tbody>
            <tr>
              <th>Pod title</th>
              <td>{{ currentPod.name }}</td>
            </tr>
            <tr>
              <th>Resource</th>
              <td>
                {{ currentPod.memory }}, {{ currentPod.cpu }},
                {{ currentPod.storage }}
              </td>
            </tr>
            <tr>
              <th>Cost @day(Tsh.)</th>
              <td>5000/=</td>
            </tr>
            <tr>
              <th>Number of days</th>
              <td>
                <input type="number" [(ngModel)]="numberOfDays" [min]="1" />
              </td>
            </tr>
            <tr>
              <th>Total Amount</th>
              <td>{{ days * 5000 }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <div style="width: 1px; background-color: grey; height: inherit;"></div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <div>
        <div class="mb-2" style="font-family: latobold;">
          <span>Payment Amount: &nbsp;</span>
          {{ paymentAmount }}
        </div>
        <div
          style="
            display: inline-flex;
            align-items: center;
            font-family: latobold;
            padding: 10px 0;
          "
        >
          <div style="font-family: latobold;">Payment Method:</div>
          &nbsp;&nbsp;
          <img src="assets/images/mpesa-logo.png" alt="" height="30px" />
          &nbsp; &nbsp;
          <div>
            <form [formGroup]="inputForm">
              <div
                class="input-box text-muted"
                [ngClass]="{ 'error-input-box': hasError }"
              >
                <div>
                  +255
                </div>
                <div
                  style="
                    height: 15px;
                    width: 1px;
                    background-color: gray;
                    margin-left: 5px;
                    margin-right: 5px;
                  "
                ></div>
                <input
                  type="text"
                  formControlName="phoneNumber"
                  style="
                    background-color: transparent;
                    border: none;
                    outline: none;
                    font-family: latobold;
                  "
                  placeholder="Phone number"
                />
              </div>
              <div *ngIf="invalidNumber" style="color: red; font-family: latobold;" @fadeIn>*Invalid phone number</div>
            </form>
            <!-- </mat-form-field> -->
          </div>
        </div>
        <div>
          <div class="mt-4" style="width: 100%;" align="center">
            <button
              *ngIf="!processing"
              class="dpod-btn save-btn"
              style="
                border-radius: 10px;
                font-family: latobold;
                display: inline-flex;
                align-items: center;
                padding-left: 40%;
              "
              [disabled]="!inputForm.valid"
              (click)="processPayment()"
            >
              <img
                src="assets/images/money-white.png"
                height="20px"
                alt=""
              />&nbsp; Pay
            </button>
            <dpod-loading
              *ngIf="processing"
              message="Processing payment..."
            ></dpod-loading>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</dpod-dialog-layout>
