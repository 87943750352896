import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dpod-pod-summary',
  templateUrl: './pod-summary.component.html',
  styleUrls: ['./pod-summary.component.css']
})
export class PodSummaryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
