<div class="container">
  <img
    matListAvatar
    style="
      width: 50%;
      height: 20%;
      display: block;
      margin-left: auto;
      margin-right: auto;
    "
    src="https://www.oakvillegym.com/wp-content/uploads/2019/09/coming-soon.png"
    alt=""
  />
</div>
