import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from '../../../core/bootstrap/settings.service';
import {CommonService} from '../../../shared/services/common.service';
// import { User } from '@dpod/api-interfaces';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'dpod-profile-settings',
  templateUrl: './settings.component.html',
})
export class ProfileSettingsComponent implements OnInit {
  profileForm: FormGroup;

  updating = false;
  toastr: any;
  user: any;
  requiredoption: boolean;
  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private userInfo: SettingsService,
    private commonService:CommonService
  ) {
    this.profileForm = this.fb.group({
      email: ['', [Validators.email]],
      firstname:[''],
      surname:[''],
      mobile: ['', []],
    });
  }

  ngOnInit() {
    this.user = this.userInfo.getUser();
      this.profileForm = this.fb.group({
        email: this.user.email,
        surname:this.user.surname,
        firstname:this.user.firstName,
        mobile: this.user.mobile,
      });
  }

  getErrorMessage(form: FormGroup) {
    return form.get('email').hasError('required')
      ? 'You must enter a value'
      : form.get('email').hasError('email')
      ? 'Not a valid email'
      : '';
  }

  async save() {
    this.updating = true;
    const formData = this.profileForm.value;
    const userPayload = {
      id: this.user.id,
      username: this.user.username,
      firstName:formData['firstname'],
      surname:formData['surname'],
      email: formData['email'],
      gender: this.user.gender,
      address: this.user.address,
      company:this.user.company,
      mobile: formData['mobile'],
    }
    try {
      const user:any = await this.http.put(`api/users/${userPayload.id}`, userPayload).toPromise();
      this.userInfo.setUser(user);
      this.commonService.success('Profile updated successfully');
      console.log('user', user);
    }catch(e) {
      this.commonService.error('Failed to update profile');
      console.error('Failed to update profile',e)
    }
    this.updating = false;
    // this.http
    //   .put('api/users', {
    //     id: this.user.id,
    //     username: this.user.username,
    //     firstName:formData['firstname'],
    //     surname:formData['surname'],
    //     password: this.user.password,
    //     email: formData['email'],
    //     gender: this.user.gender,
    //     address: this.user.address,
    //     company:this.user.company,
    //     mobile: formData['mobile'],
    //   })
    //   .subscribe(
    //     (_user: any) => {
    //       // this.updatingIn = false;
    //       // this.router.navigate(['auth', 'login'])
    //       this.userInfo.setUser(_user);
    //       location.reload();
    //     },
    //     () => {}
    //   );
  }
}
