import { Injectable } from '@angular/core';
import { Pod, PodPaymentStatus, PodStatus } from '@dpod/api-interfaces';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { HttpRequestsService } from '@dpod/client/shared/services/http.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PodModel } from '../../store/models/pod.model';

@Injectable({
  providedIn: 'root'
})
export class PodService extends EntityCollectionServiceBase<Pod>{
  [x: string]: any;


  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory, private http: HttpRequestsService) {
    super('Pod', serviceElementsFactory);
  }


  addPod(pod: Pod) {
    throw new Error("Method not implemented.");
  }
  deletePod(pod: any) {
    //throw new Error("Method not implemented.");
  }
  getPods(): Observable<PodModel[]> {
    return this.getAll().pipe(map(pods => pods.map(pod => {
      let dotColor = 'orange';
      if(pod.state === PodStatus.RUNNING) {
        dotColor = 'green'
      }else if(pod.state === PodStatus.OFFLINE) {
        dotColor = 'gray'
      }
      console.log('pod', pod);
      const newPod = {
        id: pod.id,
        name: pod.name,
        version: pod.version,
        memory: pod.resource.memory,
        cpu: pod.resource.cpu,
        storage: pod.resource.storage,
        state: pod.state,
        resource: pod.resource,
        podUser: pod.poduser,
        extraButtons: {
          boot:((( pod.state === PodStatus.IDLE) || (pod.state === PodStatus.OFFLINE)) /*&& (pod.paymentStatus === PodPaymentStatus.PAID)*/),
          reboot: pod.state === PodStatus.RUNNING,
          shutdown: pod.state === PodStatus.RUNNING,
          delete: true,
          payment:true
        },
        dotColor
      };
      console.log('pod', newPod);
      return newPod;
    })));
  }

  getPodById(id: string): Observable<PodModel> {
    return this.getByKey(id).pipe(map(pod => {
      return {
        id: pod.id,
        name: pod.name,
        version: pod.version,
        memory: pod.resource.memory,
        cpu: pod.resource.cpu,
        storage: pod.resource.storage,
        state: pod.state,
        resource: pod.resource,
        podUser: pod.poduser
      };
    }));
  }

  async create(pod: Pod): Promise<Pod> {
    return pod;
  }

  async podShutdown(podId) {
    try {
      await this.http.get(`api/pod/${podId}/shutdown`).toPromise();
    } catch (e) {
      console.error('Failed to shutdown pod', e);
    }
  }

  async podReboot(podId) {
    try {
      await this.http.get(`api/pod/${podId}/reboot`).toPromise();
    } catch (e) {
      console.error('Failed to reboot pod', e);
    }
  }

  async podBoot(podId) {
    try {
      await this.http.get(`api/pod/${podId}/boot`).toPromise();
    } catch (e) {
      console.error('Failed boot pod', e);
      throw e;
    }
  }

  async podDelete(podId) {
    try {
      const pod = await this.getByKey(podId).toPromise();
      await this.delete(pod).toPromise();
    } catch (e) {
      console.error('Failed to delete pod', e);
      throw e;
    }
  }
}
