import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { getSupportedInputTypes } from '@angular/cdk/platform';
import { PodResources, Pod, PodStatus } from '@dpod/api-interfaces';
import { PodService } from '../pod.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppState, addPod } from '@dpod/client/store';
import { Store } from '@ngrx/store';
import { SettingsService } from '../../../core/bootstrap/settings.service';

@Component({
  selector: 'dpod-add-pod',
  templateUrl: './add-pod.component.html',
  styleUrls: ['./add-pod.component.css'],
})
export class AddPodComponent implements OnInit {
  form;
  poduser = '';
  versions = ['2.30','2.31','2.32','2.33','2.33.1','2.33.8','2.34','2.35','2.36'];
  model: Pod = {
    name: '',
    poduser: '',
    state: PodStatus.IDLE,
    resource: {
      memory: '128MB',
      cpu: '100Cycles',
      storage: '1GB',
    },
    version: '2.33'
    
  };
  fields: FormlyFieldConfig[] = [
    {
      key: 'name',
      type: 'input',
      templateOptions: {
        label: 'Name',
        placeholder: 'Type the name of the pod...',
      },
    },
  ];
  _poduser: FormlyFieldConfig[] = [
    {
      key: 'poduser',
      type: 'input',
      defaultValue: this.poduser,
      hide: true
    },
  ];
  podResources = PodResources;
  modelResource = '';
  // Advanced Layout
  options: FormlyFormOptions = {};
  user: any;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddPodComponent>,
    private toastr: ToastrService,
    private podService: PodService,
    private userInfo: SettingsService,
    private dialog: MatDialog
  ) {
    this.form = this.fb.group({
      resource: ['', [Validators.required]],
      version: ['', [Validators.required]],
    });
  }

  ngOnInit() {
    this.modelResource = this.model.resource.memory;
    this.user = this.userInfo.getUser();
  }

  async submit() {
    try {
      this.model.poduser = this.user.username;
      console.log('resource', this.model);
      const payload: Pod = {
        ...this.model,
        resource: this.podResources.find(resource => resource.memory === this.modelResource)
      };
      let pod = await this.podService.add(payload).toPromise(); 
      this.dialogRef.close(true);
      this.showToast(pod);
      console.log('Pod Created', payload);
    } catch (e) {
      console.error('Failed to create pod', e);
      this.toastr.error('Failed to create pod');
    }
  }

  showToast(obj: any) {
    this.toastr.success('Create Pod Successfully.');
  }

  onClose() {
    this.dialog.closeAll();
  }
}
