<div class="mat-elevation-z2">
  <ng-container *ngIf="data?.length">
    <table
      mat-table
      class="full-width-table"
      matSort
      aria-label="Elements"
      [dataSource]="dataSource"
    >
      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>No.</th>
        <td mat-cell *matCellDef="let row; let i = index">{{ i + 1 }}</td>
      </ng-container>

      <ng-container
        *ngFor="let column of tableConfiguration.tableColumns"
        matColumnDef="{{ column.name }}"
      >
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ column.label }}
        </th>
        <td mat-cell *matCellDef="let row">
          <div style="display: flex; align-items: center;">
            <mat-icon
              *ngIf="column?.showColorDot"
              [style.color]="row['dotColor']"
              >fiber_manual_record</mat-icon
            >
            {{ row[column.name] }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
        <td mat-cell *matCellDef="let row">
          <div class="table-icons">
            <mat-icon *ngIf="tableConfiguration?.actionIcons?.edit"
              >edit</mat-icon
            >&nbsp;&nbsp;
            <mat-icon
              *ngIf="tableConfiguration?.actionIcons?.isDelete"
              style="color: red;"
              >delete</mat-icon
            >&nbsp;&nbsp;
            <mat-icon
              [matMenuTriggerFor]="menu"
              *ngIf="tableConfiguration?.extraButtons.length"
              >more_vert</mat-icon
            >
            <mat-menu #menu="matMenu">
              <ng-container
                *ngFor="let btn of tableConfiguration?.extraButtons"
              >
                <button
                  *ngIf="row['extraButtons'][btn.key]"
                  mat-menu-item
                  style="font-family: latobold;"
                  (click)="extraButtonClicked(btn.key, row['id'])"
                >
                  <mat-icon *ngIf="btn.icon">{{ btn.icon }}</mat-icon>
                  {{ btn.label }}
                </button>
                <mat-divider></mat-divider>
              </ng-container>
            </mat-menu>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        [ngClass]="routeElements"
      ></tr>
    </table>

    <mat-paginator
      #paginator
      [length]="dataSource?.data?.length"
      [pageIndex]="0"
      [pageSize]="10"
      [pageSizeOptions]="[5, 10, 20]"
      aria-label="Select page"
    >
    </mat-paginator>
  </ng-container>
  <ng-container *ngIf="!data?.length">
    <table
      mat-table
      class="full-width-table"
      matSort
      aria-label="Elements"
      [dataSource]="dataSource"
    >
      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>No.</th>
        <td mat-cell *matCellDef="let row; let i = index">{{ i + 1 }}</td>
      </ng-container>

      <ng-container
        *ngFor="let column of tableConfiguration.tableColumns"
        matColumnDef="{{ column.name }}"
      >
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ column.label }}
        </th>
        <td
          mat-cell
          *matCellDef="let row"
          [attr.colspan]="tableConfiguration.tableColumns.length"
        ></td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
        <td
          mat-cell
          *matCellDef="let row"
          [attr.colspan]="tableConfiguration.tableColumns.length"
        ></td>
      </ng-container>

      <ng-container matColumnDef="nodata">
        <td
          mat-footer-cell
          *matFooterCellDef
          [attr.colspan]="displayedColumns.length"
          style="text-align: center;"
        >
          <span class="text-muted">{{ tableConfiguration?.emptyMessage }}</span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr mat-footer-row *matFooterRowDef="['nodata']"></tr>
    </table>
  </ng-container>
</div>
