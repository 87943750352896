<dpod-dialog-layout [title]="'Create Pod'">
  <ng-container ngProjectAs="page-content">
    <div class="row">
      <div class="col-sm-6">
        <mat-form-field>
          <mat-label>Pod title</mat-label>
          <input type="text" matInput autocomplete="off" [(ngModel)]="model.name">
        </mat-form-field>
      </div>
      <div class="col-sm-6">
        <mat-form-field>
          <mat-label>Select DHIS version</mat-label>
          <mat-select [(ngModel)]="model.version" matInput>
            <mat-option *ngFor="let version of versions" [value]="version">{{version}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-sm-12 mt-2">
        <!-- <table class="table">
          <thead>
            <tr>
              <th>Memory</th>
              <th>Cpu</th>
              <th>Storage</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let resource of podResources">
              <td>{{resource.memory}}</td>
              <td>{{resource.cpu}}</td>
              <td>{{resource.storage}}</td>
              <td><mat-checkbox></mat-checkbox></td>
            </tr>
          </tbody>
        </table> -->
        <mat-form-field>
          <mat-label>Select Pod Resource</mat-label>
          <mat-select [(ngModel)]="modelResource">
            <mat-option *ngFor="let podResource of  podResources" [value]="podResource.memory">
              <b>Memory:</b>{{podResource.memory}} &nbsp; <b>CPU:</b>{{podResource.cpu}} &nbsp; <b>Storage:</b> {{podResource.storage}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-sm-12 mt-4">
        <dpod-save (save)="submit()" (cancel)="onClose()" [confirmText]="'Are you sure you want to create new pod?'" [saveDisabled]="!model.name"></dpod-save>
      </div>
    </div>
  </ng-container>
</dpod-dialog-layout>


<!-- <mat-card-title>Create a Pod</mat-card-title>

<form [formGroup]="form" (ngSubmit)="submit()">
  <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
  <formly-form [form]="form" [fields]="_poduser" [model]="model"></formly-form>
  <mat-form-field class="w-full">
    <mat-label>Select DHIS Version</mat-label>
    <mtx-select formControlName="version" [(ngModel)]="model.version">
      <mtx-option *ngFor="let version of versions" [value]="version">
        {{ version }}
      </mtx-option>
    </mtx-select>
  </mat-form-field>
  <mat-form-field class="w-full">
    <mat-label>Select Pod Resource</mat-label>
    <mtx-select formControlName="resource" [(ngModel)]="model.resource">
      <mtx-option
        *ngFor="let podResource of podResources"
        [value]="podResource"
      >
        {{ podResource.memory }}
      </mtx-option>
    </mtx-select>
  </mat-form-field>
  <button mat-raised-button color="primary">Create</button>
</form> -->
