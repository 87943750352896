import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
// import { AppMenu } from '../../../app-menus';
import { ROUTE_ANIMATIONS_ELEMENTS } from '../../animations/router-animation';

@Component({
  selector: 'dpod-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit {
  @Input() title='';
  @Input() icon;
  @Input() showClose = false;
  @Output() close = new EventEmitter();
  // @Input() menus:AppMenu[] = [];
  routeElements = ROUTE_ANIMATIONS_ELEMENTS;
  constructor(private dialog:MatDialog) {}

  ngOnInit(): void {
    console.log('top')
  }

  onClose() {
    this.close.emit()
  }

}
