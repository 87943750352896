import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MtxGridColumn } from '@ng-matero/extensions';
import { PodService } from './pod.service';
import { delEmptyKey } from '@dpod/client/shared';
import { MatDialog } from '@angular/material/dialog';
import { AddPodComponent } from './add-pod/add-pod.component';
import { Store, select } from '@ngrx/store';
import { Pod, PodAction } from '@dpod/api-interfaces';
import { AppState, selectPods, selectError, getPods } from '@dpod/client/store';
import { Observable, Subscription } from 'rxjs';
import { SettingsService } from '../../core/bootstrap/settings.service';
import { TableConfiguration } from '@dpod/client/shared/components/data-table/tableconfiguration';
import { HttpRequestsService } from '@dpod/client/shared/services/http.service';
import { ConfirmDialogComponent } from '@dpod/client/shared/components/confirm-dialog/confirm-dialog.component';
import { PodModel } from '@dpod/client/store/models/pod.model';
import { find, map } from 'rxjs/operators';
import { PaymentComponent } from '@dpod/client/shared/components/payment/payment.component';

@Component({
  selector: 'dpod-pods',
  templateUrl: './pods.component.html',
  styleUrls: ['./pods.component.css']
})
export class PodsComponent implements OnInit {

  columns: MtxGridColumn[] = [
    {
      header: 'Name',
      field: 'name',
      formatter: (data: any) => `<a href="${data.html_url}" target="_blank">${data.name}</a>`,
    },
    { header: 'Owner', field: 'owner.login' },
    { header: 'Owner Avatar', field: 'owner.avatar_url', type: 'image' },
    { header: 'Description', field: 'description', width: '300px' },
    { header: 'Stars', field: 'stargazers_count' },
    { header: 'Forks', field: 'forks_count' },
    { header: 'Score', field: 'score' },
    { header: 'Issues', field: 'open_issues' },
    { header: 'Language', field: 'language' },
    { header: 'License', field: 'license.name' },
    { header: 'Home Page', field: 'homepage', type: 'link' },
    { header: 'Is forked', field: 'fork', type: 'boolean' },
    {
      header: 'Archived',
      field: 'archived',
      type: 'tag',
      tag: {
        true: { text: 'Yes', color: 'red-100' },
        false: { text: 'No', color: 'green-100' },
      },
    },
    { header: 'Created Date', field: 'created_at' },
    { header: 'Updated Date', field: 'updated_at' },
  ];
  list = [];
  total = 0;
  isLoading = true;

  query = {
    q: 'user:nzbin',
    sort: 'stars',
    order: 'desc',
    page: 0,
    per_page: 5,
  };
  user: any;

  get params() {
    const p = Object.assign({}, this.query);
    p.page += 1;
    return delEmptyKey(p);
  }

  podLoading$ = this.podService.loading$;
  podError$ = this.podService.errors$;
  pods$: Observable<PodModel[]>;
  tableConfigurations: TableConfiguration = {
    emptyMessage: 'You have no pods yet, you can create one by clicking the plus button at the bottom',
    tableColumns: [
      { name: 'name', label: 'Title' },
      { name: 'version', label: 'DHIS2 version' },
      { name: 'memory', label: 'RAM' },
      { name: 'cpu', label: 'CPU' },
      { name: 'storage', label: 'Storage' },
      { name: 'state', label: 'State', showColorDot: true },

    ],
    extraButtons: [
      {
        label: 'Boot',
        key: 'boot',
        icon: 'trending_up'
      },
      {
        label: 'Reboot',
        key: 'reboot',
        icon: 'refresh'
      },
      {
        label: 'Shutdown',
        key: 'shutdown',
        icon: 'power_settings_new'
      },
      {
        label: 'Payment',
        key: 'payment',
        icon: 'attach_money'
      },
      {
        label: 'Delete',
        key: 'delete',
        icon: 'delete'
      }
    ]
  };
  podSubscription: Subscription;
  constructor(
    // private store: Store<AppState>, 
    private podService: PodService,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog,
    private userInfo: SettingsService,
    private http: HttpRequestsService,
  ) {
    //this.store.pipe(select(selectPods));
  }

  ngOnInit() {
    this.pods$ = this.podService.getPods();
    this.user = this.userInfo.getUser();
  }

  ngOnDestroy() {
  }
  openPanel() {
    this.dialog.open(AddPodComponent, {
      width: '60%',
      disableClose: true
    }).afterClosed().subscribe((update) => {
      if (update) {
        this.pods$ = this.podService.getPods();
      }
    });
  }

  async podAction(event) {
    // if (event.key === 'boot') 
    const pod = await this.pods$.pipe(map(pods => pods.find(pod => pod.id === event.id))).toPromise();
    if (event.key === 'payment') {
      this.dialog.open(PaymentComponent, { width: '50%', data: { pod }, disableClose: true }).afterClosed().subscribe(() => {
        this.pods$ = this.podService.getPods();
      });
    } else {
      this.dialog.open(ConfirmDialogComponent, { disableClose: true, width: '50%', data: { confirmText: 'Are you sure you want to boot this pod?', key: event.key, pod } }).afterClosed().subscribe((update: boolean) => {
        if (update) {
          this.pods$ = this.podService.getPods();
        }
      });
    }
    // await this.podService.podBoot(event.id);
  }
}
