// export enum PodState {
//   IDLE = 0,
//   STARTING = 1,
//   RUNNING = 2,
// }

export enum PodStatus {
  IDLE = 'Idle',
  RUNNING = 'Running',
  OFFLINE = 'Offline',
  BOOTING = 'Booting',
  REBOOTING = 'Rebooting',
  SHUTTINGDOWN = 'Shutting down',
  DELETING = 'Deleting'
}

export enum PodPaymentStatus {
  PAID = 'Paid',
  NOTPAID = 'Not Paid',
}

export enum PodAction {
  BOOT = 'boot',
  REBOOT = 'reboot',
  SHUTDOWN = 'shutdown',
  DELETE = 'delete',
}

// export type PodState = PodStatus.IDLE | PodStatus.RUNNING | PodStatus.OFFLINE | PodStatus.BOOTING | PodStatus.REBOOTING | PodStatus.DELETING | PodStatus.SHUTTINGDOWN;
export type PodState = PodStatus;
// export type PodPaymentState = PodPaymentStatus;
export type PodVersion = '2.33' | '2.34';
export class PodResource {
  memory: string;
  cpu: string;
  storage: string;
  version?: PodVersion;
}
export interface Pod {
  id?: string;
  name: string;
  version: PodVersion;
  state: PodState;
  paymentStatus?: PodPaymentStatus;
  paymentDate?: Date;
  paymentDueDate?: Date;
  resource: PodResource;
  poduser: string;
}

export const PodResources: PodResource[] = [
  {
    memory: '128MB',
    cpu: '100Cycles',
    storage: '1GB',
  },
  {
    memory: '256MB',
    cpu: '100Cycles',
    storage: '1GB',
  },
];

export interface podAccess {
  userid: string;
  podid: string;
}
