import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dpod-storage-usage',
  templateUrl: './storage-usage.component.html',
  styleUrls: ['./storage-usage.component.css']
})
export class StorageUsageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
