import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { PodResources } from '@dpod/api-interfaces';

@Component({
  selector: 'dpod-pod-resize',
  templateUrl: './pod-resize.component.html',
  styleUrls: ['./pod-resize.component.css'],
})
export class PodResizeComponent implements OnInit, AfterViewInit {
  @Input() podId;
  constructor(private http: HttpClient) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    console.log('PodResizeID: ', this.podId);
  }

  podResize() {
    this.http.get(`api/pod/${this.podId}/resize`, {}).subscribe(); 
  }
}
