import { NgModule } from '@angular/core';
import { SharedModule } from '@dpod/client/shared/shared.module';
import { RoutesRoutingModule } from './routes-routing.module';

import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './sessions/login/login.component';
import { RegisterComponent } from './sessions/register/register.component';
import { PodsComponent } from './pods/pods.component';
import { AddPodComponent } from './pods/add-pod/add-pod.component';
import { PodDetailsComponent } from './pods/pod-details/pod-details.component';
import { PodSummaryComponent } from './pods/pod-details/pod-summary/pod-summary.component';
import { PodNetworkingComponent } from './pods/pod-details/pod-networking/pod-networking.component';
import { PodResizeComponent } from './pods/pod-details/pod-resize/pod-resize.component';
import { PodBackupComponent } from './pods/pod-details/pod-backup/pod-backup.component';
import { CpuUsageComponent } from './pods/pod-details/pod-summary/cpu-usage/cpu-usage.component';
import { NetworkUsageComponent } from './pods/pod-details/pod-summary/network-usage/network-usage.component';
import { MemoryUsageComponent } from './pods/pod-details/pod-summary/memory-usage/memory-usage.component';
import { StorageUsageComponent } from './pods/pod-details/pod-summary/storage-usage/storage-usage.component';
import { UserAccountComponent } from './user-account/user-account.component';
import { MaterialModule } from '../material.module';
import {ProfileSettingsComponent} from '../routes/user-account/settings/settings.component';

const COMPONENTS = [DashboardComponent, LoginComponent, RegisterComponent,UserAccountComponent, ProfileSettingsComponent];
const COMPONENTS_DYNAMIC = [];

@NgModule({
  imports: [SharedModule, RoutesRoutingModule, MaterialModule],
  declarations: [...COMPONENTS, ...COMPONENTS_DYNAMIC, PodsComponent, AddPodComponent, PodDetailsComponent, PodSummaryComponent, PodNetworkingComponent, PodResizeComponent, PodBackupComponent, CpuUsageComponent, NetworkUsageComponent, MemoryUsageComponent, StorageUsageComponent],
  entryComponents: COMPONENTS_DYNAMIC,
})
export class RoutesModule {}
