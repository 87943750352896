<div class="row">
  <div class="card" style="width: 200px; margin: 0.5%;">
    <!-- <img class="card-img-top" src="img_avatar1.png" alt="Card image"> -->
    <div class="card-body">
      <h4 class="card-title">Current Package</h4>
      <hr />
      <p class="card-text"></p> 
      <p>
        Memory
        <span class="badge badge-secondary" style="float: right;">128 MB</span>
      </p>
      <p>
        CPU
        <span class="badge badge-secondary" style="float: right;">100 CYCLES</span>
      </p>
      <p>
        Storage
        <span class="badge badge-secondary" style="float: right;">1 GB</span>
      </p>
      <p>
        <a href="#" class="btn btn-outline-secondary btn-sm btn-block disabled" (click)="podResize()">Resize</a>
      </p>
    </div>
  </div>
  <div class="card" style="width: 200px; margin: 0.5%;">
    <!-- <img class="card-img-top" src="img_avatar1.png" alt="Card image"> -->
    <div class="card-body">
      <h4 class="card-title">Resizing Package</h4>
      <hr />
      <p class="card-text"></p>
      <p>
        Memory
        <span class="badge badge-secondary" style="float: right;">256 MB</span>
      </p>
      <p>
        CPU
        <span class="badge badge-secondary" style="float: right;">200 CYCLES</span>
      </p>
      <p>
        Storage
        <span class="badge badge-secondary" style="float: right;">2 GB</span>
      </p>
      <p>
        <a href="#" class="btn btn-outline-secondary btn-sm btn-block" (click)="podResize()">Resize</a>
      </p>
    </div>
  </div>
  <div class="card" style="width: 200px; margin: 0.5%;">
    <!-- <img class="card-img-top" src="img_avatar1.png" alt="Card image"> -->
    <div class="card-body">
      <h4 class="card-title">Resizing Package</h4>
      <hr />
      <p class="card-text"></p>
      <p>
        Memory
        <span class="badge badge-secondary" style="float: right;">512 MB</span>
      </p>
      <p>
        CPU
        <span class="badge badge-secondary" style="float: right;">300 CYCLES</span>
      </p>
      <p>
        Storage
        <span class="badge badge-secondary" style="float: right;">3 GB</span>
      </p>
      <p>
        <a href="#" class="btn btn-outline-secondary btn-sm btn-block" (click)="podResize()">Resize</a>
      </p>
    </div>
  </div>
  <div class="card" style="width: 200px; margin: 0.5%;">
    <!-- <img class="card-img-top" src="img_avatar1.png" alt="Card image"> -->
    <div class="card-body">
      <h4 class="card-title">Resizing Package</h4>
      <hr />
      <p class="card-text"></p>
      <p>
        Memory
        <span class="badge badge-secondary" style="float: right;">1 GB</span>
      </p>
      <p>
        CPU
        <span class="badge badge-secondary" style="float: right;">400 CYCLES</span>
      </p>
      <p>
        Storage
        <span class="badge badge-secondary" style="float: right;">4 GB</span>
      </p>
      <p>
        <a href="#" class="btn btn-outline-secondary btn-sm btn-block" (click)="podResize()">Resize</a>
      </p>
    </div>
  </div>
</div>
<div class="row">
  <!-- <div class="alert alert-info">
    <strong>Info!</strong> If you're expecting a temporary burst of traffic to
    your pod, or if you're not using your Pod as much as you thought, you can
    temporarily or permanently resize your Linode to a different plan...
  </div> -->
</div>