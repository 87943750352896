<div [ngClass]="routeElements">
  <mat-toolbar style="display: inline-flex; align-items: center;" style ="background-color: rgb(248, 248, 248);">
    <img src="assets/images/{{ icon }}" alt="" height="30px" width="30px" />&nbsp;&nbsp;&nbsp;
    <span>{{ title }}</span>
  </mat-toolbar>
</div>
<mat-divider></mat-divider>
<div style="width: 100%;">
  <div style="padding: 10px;" [ngClass]="routeElements">
    <ng-content select="page-content"></ng-content>
  </div>
  <div style="position: absolute; bottom: 10px; width: 100%; padding: 10px;">
    <button mat-fab (click)="onAdd()" *ngIf="showAdd" style="float: right;">
      <mat-icon aria-label="Example icon-button with a settings icon"
        >add</mat-icon
      >
    </button>
  </div>
</div>
<!-- <div
  class="customizer-handle"
  matTooltip="Drag me around"
  *ngIf="showAdd"
></div> -->
