import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-register',
  templateUrl: './register.component.html',
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;

  loggingIn = false;
  showPassword = false;
  showConfirmPassword = false;
  constructor(
    private toastr: ToastrService,
    private router: Router,
    private _fb: FormBuilder,
    private http: HttpClient
  ) {
    this.registerForm = this._fb.group({
      firstname:['', [Validators.required]],
      surname:['', [Validators.required]],
      username: ['', [Validators.required]],
      email:['',[Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      confirmPassword: ['', [this.confirmValidator]],
    });
  }

  ngOnInit() {}

  confirmValidator = (control: FormControl): { [k: string]: boolean } => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (control.value !== this.registerForm.controls.password.value) {
      return { error: true, confirm: true };
    }
    return {};
  };

  register() {
    this.loggingIn = true;
    const formData = this.registerForm.value;
    this.http
      .post('api/auth/register', {
        username: formData['username'],
        password: formData['password'],
        firstName: formData['firstname'],
        surname:formData['surname'],
        email: formData['email'],
        gender: '',
        address: '',
        company: '',
        mobile: ''
      })
      .subscribe(
        (user) => {
          this.loggingIn = false;
          this.router.navigate(['auth', 'login']);
          this.toastr.success('You are register successfully. Please login');
        },
        (error) => {
          this.loggingIn = false;
          this.toastr.error(error.error.message);
        }
      );
  }
}
