<dpod-shared-layout [title]="'Profile'" icon="user.png">
  <ng-container ngProjectAs="page-content">
    <mat-tab-group>
      <mat-tab>
        <ng-template matTabLabel>
          <div
            style="
              display: inline-flex;
              align-items: center;
              font-family: latobold;
            "
          >
            <img src="assets/images/profile.png" height="20px" alt="" /> &nbsp;
            Profile
          </div>
        </ng-template>
        <ng-template matTabContent>
          <div style="padding: 10px;">
            <dpod-profile-settings></dpod-profile-settings>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template matTabLabel>
          <div style="display: inline-flex; align-items: center;">
            <img src="assets/images/billing.png" height="20px" alt="" /> &nbsp;
            Billing Info
          </div>
        </ng-template>
        <ng-template matTabContent>
          Comming soon
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </ng-container>
</dpod-shared-layout>
