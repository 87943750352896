import { Injectable, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class HttpRequestsService {

  constructor(private  http:HttpClient) {}

  get(url) {
    // const reqUrl = this.http_url + url
    return this.http.get(url);
  }

  post(url, body) {
    // const reqUrl = this.http_url + url
    return this.http.post(url, body);
  }

  delete(url:string) {
    // const reqUrl = this.http_url + url
    return this.http.delete(url);
  }
  // postRequest(url,data) {}
}
