<div class="container">
  <mat-card>
    <mat-card-title>Edit Profile</mat-card-title>
    <mat-divider></mat-divider>
    <mat-card-content>
      <div class="mt-4">
        <form [formGroup]="profileForm" class="form-field-full">
          <div class="row">
            <div class="col-sm-6">
              <mat-form-field>
                <mat-label>First name</mat-label>
                <input type="text" matInput formControlName="firstname" />
              </mat-form-field>
            </div>
            <div class="col-sm-6">
              <mat-form-field>
                <mat-label>Surname</mat-label>
                <input type="text" matInput formControlName="surname" />
              </mat-form-field>
            </div>
            <div class="col-sm-6">
              <mat-form-field>
                <mat-label>Email</mat-label>
                <input type="text" matInput formControlName="email" />
              </mat-form-field>
            </div>
            <div class="col-sm-6">
              <mat-form-field>
                <mat-label>Phone number</mat-label>
                <input type="text" matInput formControlName="mobile" />
              </mat-form-field>
            </div>
            <div class="col-sm-12 mt-2">
              <dpod-save *ngIf="!updating" [showCancel]="false" [confirmText]="'Are you sure you want to update profile?'" (save)="save()"></dpod-save>
              <dpod-loading *ngIf="updating" message="Updating profile..."></dpod-loading>
            </div>
          </div>
        </form>
      </div>
    </mat-card-content>
  </mat-card>
</div>
