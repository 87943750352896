import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dpod-network-usage',
  templateUrl: './network-usage.component.html',
  styleUrls: ['./network-usage.component.css']
})
export class NetworkUsageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
