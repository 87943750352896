import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    public snackBar: MatSnackBar) { }

  notify(
    message: string = 'Success',
    panelClass: string = 'success',
    duration: number = 2500
  ) {
    this.snackBar.open(message, 'Ok', {
      duration,
      horizontalPosition: 'center',
      panelClass,
    });
  }

  success(message: string = 'Success', duration = 2500) {
    this.notify(message, 'success', duration);
  }

  warning(message: string = 'Success', duration = 2500) {
    this.notify(message, 'warning');
  }

  errorLogin(message: string = 'Error Logging In', duration: 3000) {
    this.notify(message, 'error', duration);
  }

  error(message: string = 'Operation Failed', duration: number = 5500) {
    message = message + '';
    message = message.replace('GraphQL error:', '');
    this.notify(message, 'error', duration);
  }
}
