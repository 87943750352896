import { Component, OnInit } from '@angular/core';
import { User } from '@dpod/api-interfaces';
import { SettingsService } from '@dpod/client/core/bootstrap/settings.service';

@Component({
  selector: 'dpod-user-account',
  templateUrl: './user-account.component.html',
  styleUrls: ['./user-account.component.css']
})
export class UserAccountComponent implements OnInit {
  user:User
  constructor(private userInfo:SettingsService) { }

  ngOnInit(): void {
    this.user = this.userInfo.getUser();
  }

}
